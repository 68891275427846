<template>
  <v-app>
    <v-main>
      <v-snackbar v-model="snackbar" top color="warning">
        <span>Are you sure?</span>
        <v-btn
          color="white"
          @click="
            snackbar = false;
            deleteplace(indexToEdit);
          "
          >Yes</v-btn
        >
        <v-btn text color="white" @click="snackbar = false">No</v-btn>
      </v-snackbar>

      <v-row>
        <v-col cols="12" class="pt-0">
          <v-mapbox
            access-token="pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ"
            map-style="mapbox://styles/mapbox/satellite-streets-v11"
            :center="[106.9057, 47.8864]"
            :zoom="9.5"
            id="map"
            ref="map"
            height="100vh"
          ></v-mapbox>
        </v-col>
      </v-row>

      <v-overlay :value="overlay">
        <v-card class="pa-5">
          <v-row justify="space-between">
            <v-btn
              large
              color="success"
              @click.prevent="addItem"
              height="40px"
              class="ml-3"
              >Add Place</v-btn
            >
            <v-btn color="error" @click="overlay = !overlay" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>

          <v-text-field
            label="Place оруулаx"
            dense
            v-model="newItem"
            @keyup.enter.prevent="addItem"
          ></v-text-field>
        </v-card>
      </v-overlay>
      <v-dialog max-width="400px" v-model="dialog">
        <v-card>
          <v-card-text>
            <v-form class="px-3" ref="form">
              <v-row justify="center">
                <v-date-picker class="mt-10" v-model="picker"></v-date-picker>
                <v-btn class="info mt-3" :loading="loading" @click="submit"
                  >Set Date</v-btn
                >
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");

import mapboxgl from "mapbox-gl";
import firebase from "firebase/app";
import ImageAdd from "./ImageAdd.vue";
import draggable from "vuedraggable";
export default {
  components: {
    ImageAdd,
    draggable,
  },
  data() {
    return {
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      selectedTab: "all",
      mapcoords: [],
      newItem: "",
      labels: ["all", "notcompleted", "completed", "running"],
      overlay: false,
      updatedItem: "",
      snackbar: false,
      editMode: false,
      editModeCoords: false,
      newTitle: null,
      indexToEdit: 0,
      lat: null,
      lng: null,
      latclick: null,
      lngclick: null,
      newLng: null,
      newLat: null,
      distance: null,

      //add dialog
      dialog: false,
      title: "",
      content: "",
      due: null,
      userId: "",
      alerta: false,
      dateRules: [(v) => v.length >= 4 || "Invalid format. Click to pick"],
      loading: false,
      planned: null,
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  watch: {},
  computed: {
    filteredList: function () {
      if (this.selectedTab == "all") return this.mapcoords;
      else if (this.selectedTab == "completed")
        return this.mapcoords.filter((item) => {
          if (item.status == "completed") return true;
          else return false;
        });
      else if (this.selectedTab == "running")
        return this.mapcoords.filter((item) => {
          if (item.status == "running") return true;
          else return false;
        });
      else if (this.selectedTab == "notcompleted")
        return this.mapcoords.filter((item) => {
          if (item.status == "notcompleted") return true;
          else return false;
        });
      else return this.mapcoords;
    },
  },

  created() {
    fb.db
      .collection("/countries/Mongolia/trips/lAQ23xAz7v2LuGkA3l4I/planned")
      .onSnapshot((docs) => {
        this.planned = [];
        docs.forEach((doc) => {
          let tripDay = doc.data();
          tripDay.id = doc.id;
          tripDay.ref = doc.ref;

          this.planned.push(tripDay);
        });
      });
  },
  mounted() {
    let map = this.$refs.map.map;
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ";

    map.on("load", () => {
      var _this = this;
      if (this.planned) {
        for (var plannedDay of this.planned) {
          for (var location of plannedDay.locations) {
            console.log(location.gpsfield);
            this.lastClickedPoint = new mapboxgl.Marker()
              .setLngLat([
                location.gpsfield.longitude,
                location.gpsfield.latitude,
              ])
              .addTo(map);
          }
        }
      }
      _;
      map.on("click", function (e) {
        _this.lng = e.lngLat.lng;
        _this.lat = e.lngLat.lat;

        // console.log(this.lngclick, this.latclick);

        _this.submit();
        _this.overlay = !_this.overlay;
      });
      // async function addItem(lng, lat) {
      //   console.log(lng, lat);
      //   const location = new firebase.firestore.GeoPoint(
      //     Number(lat),
      //     Number(lng)
      //   );

      //   await fb.db.collection("mapcoords").add({
      //     name: "dfsdfsdf",
      //     createdAt: new Date(),
      //     status: "notcompleted",
      //     coords: location,
      //   });
      // }

      map.on("click", function (e) {
        new mapboxgl.Marker().setLngLat(e.lngLat).addTo(map);
      });
    });
  },
  methods: {
    _saveOrder() {
      for (let index = 0; index < this.filteredList.length; index++) {
        const currentItem = this.filteredList[index];
        console.log(currentItem);
        // var docRef = fb.db
        //   .collection("users/" + this.userId + "/projects")
        //   .doc(currentProject.id);

        // // UPDATE PRIORITY ON DATABASE
        // docRef.set(
        //   {
        //     priority: index,
        //   },
        //   { merge: true }
        // );
      }
    },
    _addImage(place) {
      console.log("dfsdfsdf", place);
    },
    submit() {
      console.log("dljflsjdf");
      this.loading = true;
      setTimeout(function () {
        this.loading = false;
      }, 1000);
    },
    createdOverlay() {
      console.log("hi");
    },
    openDialog() {},
    async updateTitle(index) {
      const place = this.mapcoords[index];
      place.name = this.newTitle;
      this.editMode = false;
      await updateMap(place.id, { ...place });
    },
    async addItem() {
      const location = new firebase.firestore.GeoPoint(
        Number(this.lat),
        Number(this.lng)
      );

      console.log(this.lat, this.lng);
      if (this.newItem) {
        await fb.db.collection("mapcoords").add({
          // name: this.newItem,
          name: this.newItem,
          createdAt: new Date(),
          status: "notcompleted",
          coords: location,
          distance: this.distance,
        });
        this.newItem = null;
        this.lat = null;
        this.lng = null;
        this.overlay = !this.overlay;
      }
    },
    async updateLocation(index) {
      const place = this.mapcoords[index];
      place.coords = new firebase.firestore.GeoPoint(
        Number(this.newLat),
        Number(this.newLng)
      );
      this.editModeCoords = false;
      await updateMap(place.id, { ...place });
    },

    _clickTab(tab) {
      this.selectedTab = tab;
    },
    _getClass(place) {
      if (place.status == "notcompleted") {
        return "red white--text";
      } else if (place.status == "completed") return "green white--text";
      else if (place.status == "running") return "yellow";
    },

    appendString(val) {
      return val + "!";
    },
    deleteplace(index) {
      const place = this.mapcoords[index];
      fb.db.collection("mapcoords").doc(place.id).delete();
    },

    async updateItem(place) {
      if (place) {
        place.modifiedAt = new Date();
        await fb.db.collection("mapcoords").add(place);
      }
    },
    async updateStatus(place) {
      if (place.status == "notcompleted") place.status = "completed";
      else if (place.status == "completed") place.status = "running";
      else if (place.status == "running") place.status = "notcompleted";
      await updateMap(place.id, { ...place });
    },
    async updateName(place) {
      await updateMap(place.id, { ...place });
      console.log(place.id);
      this.updatedItem = "";
    },
  },
};
</script>


<style>
.tabb {
  color: red;
}
.tabb:hover {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}
.mapboxgl-map {
  overflow: auto !important;
  height: 100vh;
}
.status {
  padding: 2px 8px;
  border-radius: 4px;
}
.status:hover {
  cursor: pointer;
}
.v-application .red {
  background-color: #ffd9d9 !important;
  color: #f44336 !important;
}
.center {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.handle {
  cursor: move;
}
</style>